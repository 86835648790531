import React, { useContext } from "react"
import { useLocation } from "@reach/router"
import MagnifyingGlassIcon from "@heroicons/react/20/solid/MagnifyingGlassIcon"
import EnvelopeIcon from "@heroicons/react/24/solid/EnvelopeIcon"
import { ArticleEntity } from "../../entities/ArticleEntity"
import { Badge } from "../Badge/Badge"
import { ThemeContext } from "../Theme/Theme"
import { Image } from "../Image/Image"
import { Link } from "../Link/Link"
import {
  getAuthorUrl,
  getPrivacyUrl,
  getSearchUrl,
  getTermsUrl,
  getVerbUrl,
} from "../../utils/i18nUrls"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"
import { useIntl } from "react-intl"
import { AsideEntity } from "../../entities/AsideEntity"
import { ListAuthor } from "../ListAuthor/ListAuthor"
import clsx from "clsx"
import { Follow } from "../Follow/Follow"
import { SearchInput } from "../SearchInput/SearchInput"
import { FooterLinks } from "../FooterLinks/FooterLinks"

type PickedArticle = Pick<
  ArticleEntity,
  | "preview"
  | "url"
  | "author"
  | "title"
  | "updated_at"
  | "description"
  | "category"
>

const Article: React.FC<PickedArticle> = props => {
  const styles = useContext(ThemeContext)

  return (
    <div className="w-full flex cursor-pointer text-slate-900 mb-8 border-slate-200">
      <div>
        <Link
          url={getAuthorUrl(props.author.lang, props.author.username)}
          className="flex items-center"
        >
          <Image
            {...props.author.image}
            className="rounded-full h-6 w-6 bg-slate-200"
          />

          <p className="ml-2 space-x-1 font-medium text-slate-900 text-sm">
            <span>{props.author.name}</span>
          </p>
        </Link>

        <div className="mt-2">
          <Link
            url={props.url}
            className={`block font-display ${styles["hover:color"]} leading-tight font-bold`}
          >
            {props.title}
          </Link>
        </div>
      </div>

      <Link
        url={props.url}
        className="block overflow-hidden ml-auto h-14 w-14 rounded flex-shrink-0"
      >
        <Image
          className="object-cover w-full h-full"
          src={props.preview.src}
          alt={props.preview.alt}
        />
      </Link>
    </div>
  )
}

export const Aside: React.FC<AsideEntity> = props => {
  const location = useLocation()
  const intl = useIntl()
  const query = (location.href || "").split("?q=")[1]

  return (
    <div className="ml-auto hidden min-h-screen lg:block w-80 xl:w-96 border-l flex-shrink-0">
      <div className="p-8 sticky top-0 pt-14">
        <SearchInput />

        {props.author && (
          <div className={clsx("mt-8")}>
            <Image
              {...props.author.image}
              referrerPolicy="no-referrer"
              className="rounded-full h-16 w-16 bg-slate-100"
            />
            <p className="font-display font-medium text-slate-900 mt-4">
              {props.author.name}
            </p>
            <p className="text-slate-700">{props.author.bio}</p>

            {props.author.followable && (
              <div className="w-full flex items-center mt-4">
                <Follow id={props.author.id} />
              </div>
            )}
          </div>
        )}

        {props.articles && props.articles.length > 0 && (
          <div className="mt-12">
            <p className="font-display font-medium text-slate-900 mt-4">
              {props.searching && (
                <span>
                  <FormattedMessage id="search/articles/aside/title" /> {query}
                </span>
              )}
              {!props.searching && (
                <FormattedMessage id="aside/articles/title" />
              )}
            </p>

            <div className="mt-4">
              {props.articles.map(article => (
                <Article {...article} key={article.id} />
              ))}
            </div>
          </div>
        )}

        {props.categories && props.categories.length > 0 && (
          <div className="mt-12">
            <p className="font-display font-medium text-slate-900 mt-4">
              {props.searching && (
                <span>
                  <FormattedMessage id="search/categories/aside/title" />{" "}
                  {query}
                </span>
              )}
              {!props.searching && (
                <FormattedMessage id="aside/categories/title" />
              )}
            </p>

            <div className="mt-4">
              {props.categories.map(category => (
                <Link
                  key={category.id}
                  url={category.url}
                  className="mr-2 mb-2 inline-block"
                >
                  <Badge component="span">{category?.name}</Badge>
                </Link>
              ))}
            </div>
          </div>
        )}

        {props.verbs && props.verbs.length > 0 && (
          <div className="mt-12">
            <p className="font-display font-medium text-slate-900 mt-4">
              {!props.searching && <FormattedMessage id="aside/verbs/title" />}
            </p>

            <div className="mt-4">
              {props.verbs.map(verb => (
                <Link
                  key={verb.verb}
                  url={getVerbUrl(intl.locale, verb.id)}
                  className="mr-2 mb-2 inline-block"
                >
                  <Badge component="span">{verb?.verb}</Badge>
                </Link>
              ))}
            </div>
          </div>
        )}

        {props.authors && props.authors.length > 0 && (
          <div className="mt-12">
            <p className="font-display font-medium text-slate-900 mt-4">
              {props.searching && (
                <span>
                  <FormattedMessage id="search/authors/aside/title" /> {query}
                </span>
              )}

              {props.following && (
                <span>
                  <FormattedMessage id="bookmarks/authors/aside/title" />
                </span>
              )}

              {!props.searching && !props.following && (
                <FormattedMessage id="aside/authors/title" />
              )}
            </p>

            <div className="mt-4">
              {props.authors.map(author => (
                <ListAuthor {...author} key={author.id} />
              ))}
            </div>
          </div>
        )}

        <FooterLinks alignLeft obfuscated />
      </div>
    </div>
  )
}
