import React from "react"
import { useLocation } from "@reach/router"
import { Link } from "../Link/Link"
import BookmarkIcon from "@heroicons/react/24/outline/BookmarkIcon"
import BookmarkIconSolid from "@heroicons/react/24/solid/BookmarkIcon"
import { MobileNavigation } from "../MobileNavigation/MobileNavigation"
import { getBookmarksUrl, getBlogUrl, getHomeUrl } from "../../utils/i18nUrls"
import { AuthenticatedUserAvatar } from "../AuthenticatedUserAvatar/AuthenticatedUserAvatar"
import { Image } from "../Image/Image"
import MagnifyingGlassIcon from "@heroicons/react/24/outline/MagnifyingGlassIcon"
import MagnifyingGlassIconSolid from "@heroicons/react/24/solid/MagnifyingGlassIcon"
import NewspaperIcon from "@heroicons/react/24/outline/NewspaperIcon"
import NewspaperIconSolid from "@heroicons/react/24/solid/NewspaperIcon"

const Item: React.FC<{
  icon: any
  iconActive: any
  isActive: boolean
  url: string
  obfuscated?: boolean
}> = ({ icon: Icon, iconActive: IconActive, ...props }) => {
  return (
    <Link
      className={`md:w-16 md:h-16 h-12 w-12 mx-auto rounded transition-colors ease-in-out duration-300 hover:bg-slate-100 flex text-slate-900 items-center justify-center`}
      url={props.url}
      rel={props.obfuscated ? "obfuscated" : "follow"}
    >
      {props.isActive && <IconActive className="w-6 h-6" />}
      {!props.isActive && <Icon className="w-6 h-6" />}
    </Link>
  )
}

export const HeaderTemplate = props => {
  return (
    <Header
      {...props}
      logo={{
        src: props.logo.src?.childImageSharp || props.logo.src?.publicURL,
        alt: props.logo.alt,
      }}
    />
  )
}

export const Header = props => {
  const location = useLocation()

  return (
    <>
      <section className="bg-white hidden lg:flex fixed left-0 top-0 flex-shrink-0 w-20 flex-col justify-center h-screen border-r border-slate-200 z-20 py-4">
        <Link
          className="w-16 h-16 rounded inline-flex transition-colors ease-in-out duration-300 mx-auto items-center justify-center hover:bg-slate-100"
          url={getBlogUrl(props.language)}
        >
          <Image
            className="h-14 w-14"
            src={props.logo.src}
            alt={props.logo.alt}
          />
        </Link>

        <div className="my-auto">
          <Item
            icon={MagnifyingGlassIcon}
            iconActive={MagnifyingGlassIconSolid}
            isActive={getHomeUrl(props.language) === location.pathname}
            url={getHomeUrl(props.language)}
            obfuscated
          ></Item>

          <Item
            icon={NewspaperIcon}
            iconActive={NewspaperIconSolid}
            isActive={getBlogUrl(props.language) === location.pathname}
            url={getBlogUrl(props.language)}
            obfuscated
          ></Item>

          <Item
            icon={BookmarkIcon}
            iconActive={BookmarkIconSolid}
            isActive={location.pathname.includes(
              getBookmarksUrl(props.language)
            )}
            obfuscated
            url={getBookmarksUrl(props.language)}
          ></Item>
        </div>

        <AuthenticatedUserAvatar />
      </section>

      <MobileNavigation />
    </>
  )
}
