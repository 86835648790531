import React from "react"
import { HeaderTemplate } from "../Header/Header"
import { ThemeContext, themes } from "../Theme/Theme"
import { Aside } from "../Aside/Aside"
import { AsideEntity } from "../../entities/AsideEntity"

export const Layout: React.FC<{
  header: any
  language: any
  drawer: any
  aside: AsideEntity
  children: any
  theme: string
}> = props => {
  return (
    <ThemeContext.Provider value={themes[props.theme]}>
      <div className="lg:flex antialiased">
        <HeaderTemplate
          {...props.header}
          language={props.language}
          drawer={props.drawer}
        />

        {props.children}

        <Aside {...props.aside} language={props.language} />
      </div>
    </ThemeContext.Provider>
  )
}
