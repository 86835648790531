import clsx from "clsx"
import React, { useContext } from "react"
import { As } from "../As/As"
import { ThemeContext } from "../Theme/Theme"

export const Badge: React.FC<{
  component: string
  big?: boolean
  children: React.ReactNode
}> = props => {
  const styles = useContext(ThemeContext)

  return (
    <As
      component={props.component}
      className={clsx(
        `inline-block font-display  leading-5 bg-slate-100 text-slate-900 font-medium ${styles.anchor} rounded-full`,
        props.big && "py-2 px-6 text-base",
        !props.big && "py-1 px-3 text-sm"
      )}
    >
      {props.children}
    </As>
  )
}
