import React, { useContext } from "react"
import BookmarkIconSolid from "@heroicons/react/24/solid/BookmarkIcon"
import BookmarkIcon from "@heroicons/react/24/outline/BookmarkIcon"
import { connector, ContainerProps } from "./containers/Bookmark.container"
import clsx from "clsx"
import { ThemeContext } from "../Theme/Theme"

export const Wrapper: React.FC<{
  bookmarked: boolean
  big?: boolean
  onClick: () => void
}> = props => {
  const styles = useContext(ThemeContext)

  return (
    <>
      <div
        onClick={props.onClick}
        className={`p-2  ${styles["anchor"]}  text-slate-900 duration-300 ease-in-out transition-all rounded cursor-pointer `}
      >
        {props.bookmarked && (
          <BookmarkIconSolid
            className={clsx(props.big && "h-6 w-6", !props.big && "h-5 w-5")}
          />
        )}
        {!props.bookmarked && (
          <BookmarkIcon
            className={clsx(props.big && "h-6 w-6", !props.big && "h-5 w-5")}
          />
        )}
      </div>
    </>
  )
}

export const Container: React.FC<ContainerProps> = props => (
  <Wrapper {...props} />
)

export const Bookmark = connector(Container)
