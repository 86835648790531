import clsx from "clsx"
import React from "react"
import { ArticleEntity } from "../../entities/ArticleEntity"
import { getAuthorUrl } from "../../utils/i18nUrls"
import { Follow } from "../Follow/Follow"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"
import { Image } from "../Image/Image"
import { Link } from "../Link/Link"

export const ListAuthor: React.FC<
  ArticleEntity["author"] & { big?: boolean }
> = props => (
  <div
    className={clsx(
      "flex",
      props.big && "py-8 border-b border-slate-100",
      !props.big && "mt-2"
    )}
  >
    <Link url={getAuthorUrl(props.lang, props.username)}>
      <Image
        {...props.image}
        className={clsx(
          "rounded-full  flex-shrink-0 bg-red-200",
          props.big && "h-16 w-16",
          !props.big && "h-12 w-12"
        )}
      />
    </Link>

    <div className={clsx(props.big && "ml-8 pr-8", !props.big && "ml-2")}>
      <Link
        url={getAuthorUrl(props.lang, props.username)}
        className="font-display font-medium text-slate-900"
      >
        {props.name}
      </Link>
      <p className="text-slate-700 text-sm">
        {props.bio.slice(0, props.big ? 150 : 40)}…
      </p>
    </div>

    <div className="ml-auto my-auto flex-shrink-0">
      <Follow id={props.id} small />
    </div>
  </div>
)
