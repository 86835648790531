import React, { ReactElement, useContext, useRef } from "react"
import { useLocation } from "@reach/router"
import { H2 } from "../Mdx/components"

import ShareIcon from "@heroicons/react/20/solid/ShareIcon"
import { TwitterIcon } from "../Icons/Twitter"
import { FacebookIcon } from "../Icons/Facebook"
import { ThemeContext } from "../Theme/Theme"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"
import { useShare } from "../../utils/useShare"
import { VerbEntity, GrammarEntity } from "../../entities/VerbEntity"
import { Badge } from "../Badge/Badge"
import { useIntl } from "react-intl"
import { navigate } from "gatsby"

const ListItem: React.FC<GrammarEntity> = props => {
  const location = useLocation()

  return (
    <li className="w-full flex items-center text-lg py-3 justify-between border-t first:border-t-transparent border-slate-300 border-dashed">
      <span className="font-display text-xl font-semibold">{props.verb}</span>
      <div className="space-x-2 ml-4 lowercase">
        {props.level !== "other" && (
          <span
            className="cursor-pointer"
            onClick={() => {
              if (props.level === "base") return false
              navigate(location.pathname + `#info=${props.level}`)
            }}
          >
            <Badge component="span">
              <FormattedMessage id={`grammar/politness/${props.level}`} />
            </Badge>
          </span>
        )}
        {props.type !== "other" && (
          <span
            className="cursor-pointer"
            onClick={() => {
              if (props.type === "base") return false
              navigate(location.pathname + `#info=${props.type}`)
            }}
          >
            <Badge component="span">
              <FormattedMessage id={`grammar/type/${props.type}`} />
            </Badge>
          </span>
        )}
      </div>
    </li>
  )
}

const Icon: React.FC<{
  to: string
  name: string
  icon: ReactElement
}> = props => {
  const styles = useContext(ThemeContext)

  return (
    <a
      href={props.to}
      target="_blank"
      rel="noopener noreferrer"
      className={`p-2 ${styles["anchor"]} transition-colors ease-in-out duration-300 cursor-pointer hover:bg-slate-100 text-slate-900 rounded flex items-center justify-center`}
    >
      <span className="sr-only">{props.name}</span>

      {props.icon}
    </a>
  )
}

const Share: React.FC<{
  title: string
  description: string
  id: string
}> = props => {
  const { url, shareable } = useShare()

  return (
    <div className="hidden md:flex items-center ml-auto justify-end">
      <Icon
        name="Twitter"
        to={`https://twitter.com/intent/tweet?url=${url}&text=${encodeURIComponent(
          props.title
        )}`}
        icon={<TwitterIcon className="h-5 w-5" />}
      />

      <Icon
        name="Facebook"
        to={`https://www.facebook.com/sharer.php?u=${url}`}
        icon={<FacebookIcon className="h-5 w-5" />}
      />

      {shareable && (
        <div
          onClick={() =>
            navigator.share({
              url,
              title: props.title,
              text: props.description,
            })
          }
          className="p-2 text-gray-300 cursor-pointer hover:text-gray-400"
        >
          <ShareIcon className="h-5 w-5" />
        </div>
      )}
    </div>
  )
}

export const Verb: React.FC<VerbEntity> = props => {
  const ref = useRef<HTMLDivElement>(null)
  const intl = useIntl()
  const hasSentences = props.definitions[intl.locale]?.find(
    ({ sentences }) => sentences.length > 0
  )

  return (
    <div className="w-full lg:pl-20">
      <article ref={ref} className="pt-8 relative md:pt-14 w-full">
        <div className="max-w-3xl mx-auto">
          <div className="mx-auto max-w-3xl px-4 md:px-0 mt-6 flex justify-start items-center">
            <h1 className="text-6xl font-sans font-extrabold mr-4">
              {props.verb}
            </h1>

            <Share
              title={props.verb}
              description={props.verb}
              id={props.verb}
            />
          </div>
          <p className="mx-auto max-w-3xl px-4 md:px-0 md:text-4xl text-2xl font-semibold  text-slate-500">
            {props.definitions[intl.locale]
              .slice(0, 1)
              .map(({ definition }) => definition)}
          </p>
        </div>

        {(props.definitions[intl.locale].length > 1 || hasSentences) && (
          <section className="max-w-3xl mt-8 mx-auto">
            <section>
              <H2>
                <FormattedMessage id="verb/section/how-to-use/title" />
              </H2>
              <p className="text-slate-500 pb-2 text-lg px-4 md:px-0">
                <FormattedMessage id="verb/section/how-to-use/description" />
              </p>

              <ul className="px-4 lg:px-0">
                {props.definitions[intl.locale].map(definition => (
                  <li className="w-full text-lg py-3  border-t first:border-t-transparent border-slate-300 border-dashed">
                    <span className="text-xl font-medium">
                      {definition.definition}
                    </span>

                    <ul className="pl-4">
                      {definition.sentences.map(sentence => (
                        <li className="mt-2">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: sentence.hangeul,
                            }}
                            className="sentence"
                          />

                          <p
                            dangerouslySetInnerHTML={{
                              __html: sentence.translation,
                            }}
                            className="sentence"
                          />
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </section>
          </section>
        )}

        <section className="max-w-3xl mt-8 mx-auto">
          <section>
            <H2>
              <FormattedMessage id="verb/section/how-to-present/title" />
            </H2>
            <p className="text-slate-500 pb-2 text-lg px-4 md:px-0">
              <FormattedMessage id="verb/section/how-to-present/description" />
            </p>

            <ul className="mt-2 px-4 lg:px-0">
              {props.grammar
                .filter(
                  ({ time, level }) =>
                    time === "present" && level !== "formal-low"
                )
                .map(grammar => (
                  <ListItem
                    {...grammar}
                    key={grammar.verb + grammar.type + grammar.level}
                  />
                ))}
            </ul>
          </section>
        </section>

        <section className="max-w-3xl mt-8 mx-auto">
          <section>
            <H2>
              <FormattedMessage id="verb/section/how-to-past/title" />
            </H2>
            <p className="text-slate-500 pb-2 text-lg px-4 md:px-0">
              <FormattedMessage id="verb/section/how-to-past/description" />
            </p>

            <ul className="mt-2 px-4 lg:px-0">
              {props.grammar
                .filter(
                  ({ time, level }) => time === "past" && level !== "formal-low"
                )
                .map(grammar => (
                  <ListItem {...grammar} key={grammar.verb + grammar.type} />
                ))}
            </ul>
          </section>
        </section>

        <section className="max-w-3xl mt-8 mx-auto">
          <section>
            <H2>
              <FormattedMessage id="verb/section/how-to-future/title" />
            </H2>
            <p className="text-slate-500 pb-2 text-lg px-4 md:px-0">
              <FormattedMessage id="verb/section/how-to-future/description" />
            </p>

            <ul className="mt-2 px-4 lg:px-0">
              {props.grammar
                .filter(
                  ({ time, level }) =>
                    time === "future" && level !== "formal-low"
                )
                .map(grammar => (
                  <ListItem {...grammar} key={grammar.verb + grammar.type} />
                ))}
            </ul>
          </section>
        </section>

        <section className="max-w-3xl mt-8 mx-auto">
          <section>
            <H2>
              <FormattedMessage id="verb/section/how-to-other/title" />
            </H2>
            <p className="text-slate-500 pb-2 text-lg px-4 md:px-0">
              <FormattedMessage id="verb/section/how-to-other/description" />
            </p>

            <ul className="mt-2 px-4 lg:px-0">
              {props.grammar
                .filter(
                  ({ time, level }) =>
                    time === "other" && level !== "formal-low"
                )
                .map(grammar => (
                  <ListItem {...grammar} key={grammar.verb} />
                ))}
            </ul>
          </section>
        </section>
      </article>

      <footer className="w-full px-4 mt-10 bg-slate-50 py-10"></footer>
    </div>
  )
}
