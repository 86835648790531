import React from "react"
import { connector, ContainerProps } from "./containers/Follow.container"
import { Button } from "../Button/Button"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"

export const Wrapper: React.FC<{
  following: boolean
  small?: boolean
  onClick: () => void
}> = props => {
  return (
    <Button
      theme={props.following ? "solid" : "outline"}
      onClick={props.onClick}
      size={props.small ? "small" : "normal"}
    >
      {!props.following && <FormattedMessage id="author/follow" />}
      {props.following && <FormattedMessage id="author/unfollow" />}
    </Button>
  )
}

export const Container: React.FC<ContainerProps> = props => (
  <Wrapper {...props} />
)

export const Follow = connector(Container)
