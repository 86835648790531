import React from "react"

export const themes = {
  green: {
    bg: "bg-green-500",
    anchor: "hover:bg-green-100 hover:text-green-500",
    color: "text-green-500",
    "anchor:active": "bg-slate-100 text-slate-500",
    "hover:color": "hover:text-green-600",
    "hover:bg": "hover:bg-green-600",
    focus: "hover:ring-green-600 focus:ring-opacity-50 focus:ring-2",
    border: "border-green-600",
    theme: "green",
  },
  blue: {
    bg: "bg-blue-500",
    anchor:
      "hover:bg-blue-100 hover:text-blue-500 duration-300 ease-in-out transition-all",
    color: "text-blue-500",
    "anchor:active": "bg-slate-100 text-slate-500",
    "hover:color": "hover:text-blue-600",
    "hover:bg": "hover:bg-blue-600",
    focus: "hover:ring-blue-600 focus:ring-opacity-50 focus:ring-2",
    border: "border-blue-600",
    theme: "blue",
  },
  red: {
    anchor: "hover:bg-red-100 hover:text-red-500",
    bg: "bg-red-500",
    "anchor:active": "bg-slate-100 text-slate-500",
    color: "text-red-500",
    "hover:color": "hover:text-red-600",
    "hover:bg": "hover:bg-red-600",
    focus: "hover:ring-red-600 focus:ring-opacity-50 focus:ring-2",
    border: "border-red-600",
    theme: "red",
  },
  yellow: {
    bg: "bg-yellow-500",
    anchor:
      "hover:bg-yellow-50 hover:text-yellow-500 duration-300 ease-in-out transition-all",
    "anchor:active": "bg-yellow-100 text-yellow-500",
    color: "text-yellow-500",
    "hover:color": "hover:text-yellow-500",
    "hover:bg": "hover:bg-yellow-500",
    focus: "hover:ring-yellow-600 focus:ring-opacity-50 focus:ring-2",
    border: "border-yellow-600",
    theme: "yellow",
  },
  dark: {
    theme: "slate",
    anchor:
      "hover:bg-slate-100 hover:text-slate-500 duration-300 ease-in-out transition-all",
    "anchor:active": "bg-slate-100 text-slate-500",
    bg: "bg-slate-800",
    color: "text-slate-900",
    "hover:color": "hover:text-black",
    "hover:bg": "hover:bg-slate-900",
    border: "border-slate-900",
    focus: "hover:ring-slate-900 focus:ring-opacity-50 focus:ring-2",
  },
}

export const ThemeContext = React.createContext(themes.dark)
