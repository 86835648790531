import { connect, ConnectedProps } from "react-redux"
import { actions } from "../../../redux/actions"
import { RootState } from "../../../redux/store"

const mapState = (state: RootState, props: { id: string; small?: boolean }) => ({
  following: state.follows.follows.has(props.id),
  small: props.small
})

const mapDispatch = (dispatch: any, props: { id: string }) => ({
  onClick: () => {
    dispatch(actions.follows.$toggle({ authorId: props.id }))
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
