import React from "react"
import { Verb } from "../components/Verb/Verb"
import { Layout } from "../components/Layout/Layout"
import { Seo } from "../components/Seo/Seo"
import { VerbEntity } from "../entities/VerbEntity"
import { MobileHeader } from "../components/MobileHeader/MobileHeader"
import { useIntl } from "react-intl"

type Props = {
  pageContext: {
    verb: VerbEntity
    meta: any
    aside: any
    language: string
    configuration: any
    header: any
    footer: any
    drawer: any
    cookies: any
    theme: string
    canonicals: {
      lang: string
      url: string
      isDefault: boolean
    }[]
  }
}

const VerbTemplate: React.FC<Props> = props => {
  const intl = useIntl()

  return (
    <>
      <Seo
        title={intl.formatMessage(
          {
            id: "verb/seo/title",
          },
          {
            v: props.pageContext.verb.verb,
          }
        )}
        description={intl.formatMessage(
          {
            id: "verb/seo/description",
          },
          {
            v: props.pageContext.verb.verb,
            d: props.pageContext.verb.definitions[intl.locale][0]?.definition
          }
        )}

        domain={props.pageContext.configuration.domain}
        lang={props.pageContext.language}
        indexable={true}
        langUrls={props.pageContext.canonicals}
      />

      <Layout
        theme={props.pageContext.theme}
        header={props.pageContext.header}
        drawer={props.pageContext.drawer}
        aside={props.pageContext.aside}
        language={props.pageContext.language}
      >
        <MobileHeader
          title={props.pageContext.meta?.title}
          description={props.pageContext.meta?.description}
        />
        <Verb {...props.pageContext.verb} />
      </Layout>
    </>
  )
}

export default VerbTemplate
